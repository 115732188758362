import { ErrorBoundary, JSX, splitProps } from 'solid-js';
import { Alert } from "@suid/material";
import { CustomerTemplatePage } from './CustomerTemplatePage'
 import { getText } from '../components/SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'

interface PageTemplateInterface {
    headerPage: string;
    headerElement: string;

    type: "customer" | "candidate" | "supplier" | "brooker"
    leftPaneComponent?: JSX.Element;
    mainComponent: JSX.Element;
    buttonComponent: JSX.Element;

    children: JSX.Element;
}

const PageTemplate = (props: PageTemplateInterface) => {
    const [local] = splitProps(props, ['children'])

    const navigate = useNavigate();

    if (!isLoggedIn()) {
        navigate('/login');
        return;
    }
    const txtHeader = getText(props.headerPage, props.headerElement)
    return (
        <>
            <ErrorBoundary
                fallback={(e) => (
                    <div>
                        <Alert sx={{ px: 1, py: 1 }} severity="error">
                            Error: {e.message} .
                        </Alert>
                    </div>
                )}
            >
                <CustomerTemplatePage type={props.type} leftPaneComponent={props.leftPaneComponent} mainComponent={props.mainComponent} buttonComponent={props.buttonComponent} >{txtHeader()}</CustomerTemplatePage>
                {props.children}
            </ErrorBoundary>
        </>
    );
}
export { PageTemplate }